import { styled } from 'styled-components';

export const StatisticStyled = styled.div`
    
    .months-statistics__head {
        display: flex;
        justify-content: space-between;
        width: 100%;
     }
    
`
