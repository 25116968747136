import React, { useEffect, useState } from 'react'
import { IUser } from '../../../../models'
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useDispatch } from 'react-redux';
import { changeMessage, deleteMessage, replyMessage } from '../../../../redux/toolkitSlice';
import { NavLink } from 'react-router-dom';

interface IChatItemProps {
    isOwner?: boolean
    isEdited?: boolean
    isLocked?: boolean
    images?: string[]
    isRead?: boolean
    id: number | string
    message: string
    user: IUser
    date: Date
    replyTo: any
}

export const ChatItem: React.FC<IChatItemProps> = ({ isOwner, isEdited, message, images, isLocked, user, date, isRead, id, replyTo }) => {

    const [isLiked, setIsLiked] = useState(false);

    const handleLiked = () => {
        setIsLiked(!isLiked)
    }

    const dispatch = useDispatch()

    const handleEditMessage = () => {
        dispatch(changeMessage({
            id: String(id),
            isOwner, isEdited, text: message, images, isLocked, user, date, isRead, replyTo
        }))
    }

    const handleDeleteMessage = () => {
        dispatch(deleteMessage(String(id)))
    }
    const handleReply = () => {
        dispatch(replyMessage({
            id: String(id),
            isOwner, isEdited, text: message, images, isLocked, user, date, isRead
        }))
    }

    return (
        <div className={`content-chat__message chat-message ${isOwner ? "chat-message--main" : ""}`}>
            <div className="chat-message__block">
                <div className="chat-message__user user-item">
                    <div className="user-item__image">
                        <img className="user-photo" src={user.photo} alt={user.username} />
                    </div>
                </div>
                <div className="chat-message__content">
                    <div className="chat-message__body">
                        <div className={`chat-message__text ${isLocked ? "locked" : ""}`}>

                            {replyTo?.text && <div style={{fontSize: "11px", borderLeft: "1px solid blue", borderRadius: "7px", padding: "0 10px", background: "rgba(0, 0, 0, .2)", marginBottom: "10px"}} className="chat-message__reply">
                                <div className="user">
                                    {replyTo?.user?.username}
                                </div>
                                <div className="message">
                                    {replyTo?.text?.slice(0, 50) + "..."}
                                </div>
                            </div>}

                            <p>
                                {message}
                            </p>

                            {!!images?.length && <div data-gallery="true" className="content-post__images gallery">

                                {
                                    images.map(item => (
                                        <a href={item} data-fancybox={`gallery-${id}`} className="content-post__image gallery__image">
                                            <img src={item} alt="ph" className="ibg gallery__preview" />
                                        </a>
                                    ))
                                }

                            </div>}

                            {isLocked && <div className="content-post__image-unlock unlock">
                                <div className="unlock__body">
                                    <NavLink to={"/profile/123"} className="unlock__button button button--fw"><span>Follow to unlock</span></NavLink>
                                </div>
                            </div>}

                            <span className="chat-message__time-send">
                                {date.getHours()}:{date.getMinutes()}
                            </span>
                        </div>
                        <div className="chat-message__actions actions-chat-message">
                            {isOwner && <button onClick={handleEditMessage} className="actions-chat-message__button reply">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_360_6133)">
                                        <path d="M2.16397 14.3489C1.97197 14.3489 1.78034 14.2758 1.63372 14.1291C1.34084 13.8363 1.34084 13.3615 1.63372 13.0686L13.354 1.34838C13.6465 1.0555 14.1216 1.0555 14.4145 1.34838C14.7073 1.64125 14.7073 2.116 14.4145 2.40888L2.69422 14.1291C2.54797 14.2754 2.35597 14.3489 2.16397 14.3489Z" fill="#838383" />
                                        <path d="M1.00661 19.75C0.954484 19.75 0.901609 19.7443 0.848734 19.7331C0.443734 19.6461 0.185734 19.2475 0.272734 18.8425L1.43148 13.4413C1.51848 13.0363 1.91898 12.7791 2.32211 12.8653C2.72711 12.9523 2.98511 13.351 2.89811 13.756L1.73936 19.1571C1.66398 19.5092 1.35273 19.75 1.00661 19.75Z" fill="#838383" />
                                        <path d="M6.40713 18.5912C6.21513 18.5912 6.02351 18.5181 5.87688 18.3715C5.58401 18.0786 5.58401 17.6039 5.87688 17.311L17.5971 5.59112C17.8896 5.29824 18.3648 5.29824 18.6576 5.59112C18.9505 5.88399 18.9505 6.35874 18.6576 6.65162L6.93776 18.3715C6.79113 18.5181 6.59913 18.5912 6.40713 18.5912Z" fill="#838383" />
                                        <path d="M1.0044 19.75C0.658271 19.75 0.347396 19.5093 0.271646 19.1571C0.185021 18.7521 0.442646 18.3535 0.847646 18.2665L6.24877 17.1078C6.65415 17.0219 7.05277 17.2791 7.1394 17.6838C7.22602 18.0888 6.9684 18.4874 6.5634 18.5744L1.16227 19.7331C1.1094 19.7448 1.05652 19.75 1.0044 19.75Z" fill="#838383" />
                                        <path d="M16.006 8.9924C15.814 8.9924 15.622 8.91928 15.4757 8.77265L11.2333 4.53028C10.9405 4.2374 10.9405 3.76265 11.2333 3.46978C11.5258 3.1769 12.0013 3.1769 12.2938 3.46978L16.5362 7.71215C16.8291 8.00503 16.8291 8.47978 16.5362 8.77265C16.39 8.91928 16.198 8.9924 16.006 8.9924Z" fill="#838383" />
                                        <path d="M18.1268 6.87135C17.9348 6.87135 17.7428 6.79822 17.5962 6.6516C17.3033 6.35872 17.3033 5.88397 17.5962 5.59072C18.0136 5.17335 18.2434 4.60822 18.2434 3.99997C18.2434 3.39172 18.0136 2.8266 17.5962 2.40922C17.1784 1.99147 16.6133 1.7616 16.0051 1.7616C15.3968 1.7616 14.8317 1.99147 14.4143 2.40922C14.1218 2.7021 13.6471 2.70247 13.3534 2.40922C13.0606 2.11635 13.0606 1.6416 13.3534 1.34835C14.0539 0.647472 14.9956 0.261597 16.0051 0.261597C17.0142 0.261597 17.9562 0.647472 18.6567 1.34835C19.3576 2.04885 19.7434 2.99047 19.7434 3.99997C19.7434 5.00947 19.3576 5.9511 18.6567 6.6516C18.5108 6.79785 18.3188 6.87135 18.1268 6.87135Z" fill="#838383" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_360_6133">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>}

                            <button className={`actions-chat-message__button like ${isLiked ? 'active' : ''}`} onClick={handleLiked}>
                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.83191 18.0065L9.83083 18.0055C7.00698 15.4472 4.72355 13.3741 3.13731 11.4352C1.55929 9.50633 0.75 7.80226 0.75 5.99455C0.75 3.04245 3.05268 0.75 6 0.75C7.67171 0.75 9.2884 1.53139 10.3402 2.75575L10.9091 3.418L11.478 2.75575C12.5298 1.53139 14.1465 0.75 15.8182 0.75C18.7655 0.75 21.0682 3.04245 21.0682 5.99455C21.0682 7.80226 20.2589 9.50633 18.6809 11.4352C17.0946 13.3741 14.8112 15.4472 11.9874 18.0055L11.9863 18.0065L10.9091 18.9862L9.83191 18.0065Z" fill="transparent" stroke="#838383" stroke-width="1.5" />
                                </svg>
                            </button>

                            {isOwner && <button onClick={handleDeleteMessage} className="actions-chat-message__button reply">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.0312 2.34375H14.1016V1.75781C14.1016 0.788555 13.313 0 12.3438 0H7.65625C6.68699 0 5.89844 0.788555 5.89844 1.75781V2.34375H2.96875C1.99949 2.34375 1.21094 3.1323 1.21094 4.10156C1.21094 4.88 1.71973 5.54145 2.42207 5.77191L3.46719 18.3881C3.54246 19.292 4.31191 20 5.21891 20H14.7811C15.6881 20 16.4576 19.292 16.5329 18.3879L17.5779 5.77187C18.2803 5.54145 18.7891 4.88 18.7891 4.10156C18.7891 3.1323 18.0005 2.34375 17.0312 2.34375ZM7.07031 1.75781C7.07031 1.43473 7.33316 1.17188 7.65625 1.17188H12.3438C12.6668 1.17188 12.9297 1.43473 12.9297 1.75781V2.34375H7.07031V1.75781ZM15.365 18.2909C15.3399 18.5921 15.0834 18.8281 14.7811 18.8281H5.21891C4.9166 18.8281 4.66012 18.5921 4.63508 18.2911L3.60523 5.85938H16.3948L15.365 18.2909ZM17.0312 4.6875H2.96875C2.64566 4.6875 2.38281 4.42465 2.38281 4.10156C2.38281 3.77848 2.64566 3.51562 2.96875 3.51562H17.0312C17.3543 3.51562 17.6172 3.77848 17.6172 4.10156C17.6172 4.42465 17.3543 4.6875 17.0312 4.6875Z" fill="#838383" />
                                    <path d="M7.65516 17.034L7.06923 7.5809C7.04919 7.25789 6.76962 7.01222 6.44817 7.03234C6.12516 7.05238 5.87958 7.33043 5.89958 7.6534L6.48551 17.1066C6.50477 17.4172 6.76274 17.6562 7.06977 17.6562C7.40911 17.6562 7.67598 17.3705 7.65516 17.034Z" fill="#838383" />
                                    <path d="M10 7.03125C9.67641 7.03125 9.41406 7.29359 9.41406 7.61719V17.0703C9.41406 17.3939 9.67641 17.6562 10 17.6562C10.3236 17.6562 10.5859 17.3939 10.5859 17.0703V7.61719C10.5859 7.29359 10.3236 7.03125 10 7.03125Z" fill="#838383" />
                                    <path d="M13.5519 7.03239C13.2296 7.01236 12.9508 7.25794 12.9308 7.58095L12.3449 17.0341C12.3249 17.357 12.5705 17.6351 12.8935 17.6551C13.2166 17.6751 13.4945 17.4294 13.5145 17.1066L14.1005 7.65345C14.1205 7.33044 13.8749 7.05239 13.5519 7.03239Z" fill="#838383" />
                                </svg>
                            </button>}

                            {!isOwner && <button onClick={handleReply} className="actions-chat-message__button reply">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0303 6.47001C10.1708 6.61064 10.2497 6.80126 10.2497 7.00001C10.2497 7.19876 10.1708 7.38939 10.0303 7.53001L6.31032 11.25H14.5003C15.4533 11.25 16.8673 11.53 18.0633 12.391C19.2983 13.281 20.2503 14.756 20.2503 17C20.2503 17.1989 20.1713 17.3897 20.0306 17.5303C19.89 17.671 19.6992 17.75 19.5003 17.75C19.3014 17.75 19.1106 17.671 18.97 17.5303C18.8293 17.3897 18.7503 17.1989 18.7503 17C18.7503 15.244 18.0353 14.22 17.1873 13.609C16.3003 12.97 15.2133 12.75 14.5003 12.75H6.31032L10.0303 16.47C10.104 16.5387 10.1631 16.6215 10.2041 16.7135C10.2451 16.8055 10.2671 16.9048 10.2689 17.0055C10.2707 17.1062 10.2522 17.2062 10.2144 17.2996C10.1767 17.393 10.1206 17.4778 10.0494 17.549C9.97814 17.6203 9.8933 17.6764 9.79991 17.7141C9.70653 17.7519 9.6065 17.7704 9.50579 17.7686C9.40509 17.7668 9.30578 17.7448 9.21378 17.7038C9.12178 17.6628 9.03898 17.6037 8.97032 17.53L3.97032 12.53C3.82987 12.3894 3.75098 12.1988 3.75098 12C3.75098 11.8013 3.82987 11.6106 3.97032 11.47L8.97032 6.47001C9.11094 6.32956 9.30157 6.25067 9.50032 6.25067C9.69907 6.25067 9.88969 6.32956 10.0303 6.47001Z" fill="#A3A3A3">
                                    </path>
                                </svg>
                            </button>}
                        </div>
                    </div>
                    <div className="chat-message__bottom">
                        {isEdited && <p className="chat-message__edited">Edited</p>}

                        <div className={`chat-message__status status-message ${isRead ? "read" : "done"}`}>
                            <div className="status-message__icon status-message__icon--done">
                                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.6884 1.19592L4.68837 8.07092C4.57147 8.1859 4.41407 8.25034 4.25009 8.25034C4.08612 8.25034 3.92871 8.1859 3.81181 8.07092L0.811812 5.12482C0.753282 5.06727 0.706661 4.99875 0.674611 4.92317C0.642562 4.8476 0.625711 4.76645 0.625022 4.68437C0.624333 4.60228 0.639818 4.52086 0.670594 4.44476C0.70137 4.36866 0.746834 4.29937 0.80439 4.24084C0.861946 4.18231 0.930467 4.13569 1.00604 4.10364C1.08161 4.07159 1.16276 4.05474 1.24485 4.05405C1.32693 4.05336 1.40835 4.06884 1.48445 4.09962C1.56055 4.1304 1.62984 4.17586 1.68837 4.23342L4.25009 6.74904L10.8126 0.303729C10.9309 0.18749 11.0905 0.12301 11.2564 0.124475C11.3385 0.125201 11.4197 0.142094 11.4953 0.174191C11.5709 0.206288 11.6394 0.252959 11.697 0.311541C11.7545 0.370123 11.8 0.439468 11.8307 0.515616C11.8615 0.591765 11.8769 0.673225 11.8762 0.755347C11.8755 0.837469 11.8586 0.918643 11.8265 0.994236C11.7944 1.06983 11.747 1.13836 11.6884 1.19592Z" fill="#6F767E" />
                                </svg>
                            </div>
                            <div className="status-message__icon status-message__icon--read">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.6884 6.69613L4.68837 13.5711C4.57147 13.6861 4.41407 13.7506 4.25009 13.7506C4.08612 13.7506 3.92871 13.6861 3.81181 13.5711L0.811812 10.625C0.753282 10.5675 0.706661 10.499 0.674611 10.4234C0.642562 10.3478 0.625711 10.2667 0.625022 10.1846C0.624333 10.1025 0.639818 10.0211 0.670594 9.94498C0.70137 9.86888 0.746834 9.79958 0.80439 9.74105C0.861946 9.68252 0.930467 9.6359 1.00604 9.60385C1.08161 9.5718 1.16276 9.55495 1.24485 9.55426C1.32693 9.55357 1.40835 9.56906 1.48445 9.59984C1.56055 9.63061 1.62984 9.67608 1.68837 9.73363L4.25009 12.2493L10.8126 5.80394C10.9309 5.6877 11.0905 5.62323 11.2564 5.62469C11.3385 5.62542 11.4197 5.64231 11.4953 5.67441C11.5709 5.7065 11.6394 5.75317 11.697 5.81176C11.7545 5.87034 11.8 5.93968 11.8307 6.01583C11.8615 6.09198 11.8769 6.17344 11.8762 6.25556C11.8755 6.33768 11.8586 6.41886 11.8265 6.49445C11.7944 6.57004 11.7477 6.63858 11.6892 6.69613H11.6884ZM19.1962 5.81176C19.1387 5.75313 19.0701 5.70642 18.9945 5.6743C18.9189 5.64217 18.8378 5.62526 18.7556 5.62454C18.6735 5.62381 18.592 5.63928 18.5159 5.67007C18.4397 5.70085 18.3704 5.74634 18.3118 5.80394L11.7493 12.2493L10.2782 10.8039C10.1599 10.6878 10.0003 10.6234 9.83453 10.625C9.66875 10.6265 9.51037 10.6938 9.39423 10.8121C9.2781 10.9305 9.21372 11.0901 9.21526 11.2558C9.21679 11.4216 9.28413 11.58 9.40244 11.6961L11.311 13.5711C11.4279 13.6861 11.5853 13.7506 11.7493 13.7506C11.9133 13.7506 12.0707 13.6861 12.1876 13.5711L19.1876 6.69613C19.2463 6.63865 19.293 6.57017 19.3252 6.4946C19.3574 6.41903 19.3744 6.33786 19.3752 6.25573C19.376 6.17359 19.3606 6.0921 19.3299 6.01592C19.2992 5.93974 19.2537 5.87037 19.1962 5.81176Z" fill="#329993" />
                                </svg>
                            </div>
                        </div>

                        <p className="chat-message__time">
                            <span>
                                {date.getHours()}:{date.getMinutes()}
                            </span>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )
}
